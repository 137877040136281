import { memo } from 'react'
import { ProjectCardButton } from 'root/src/client/web/list/listItemComponents/project/ProjectCardButton'
import Link from 'root/src/client/web/base/Link'
import { useProjectCard } from 'root/src/client/web/list/listItemComponents/project/hooks/useProjectCard'
import { useListRouteId } from 'root/src/client/web/list/hooks/useListRouteId'
import * as BlackCard from 'root/src/client/v2/common/components/BlackCard/BlackCard'
import { ProjectCardHeader } from 'root/src/client/web/list/listItemComponents/project/ProjectCardHeader'
import { ProjectCardDetails } from 'root/src/client/web/list/listItemComponents/project/ProjectCardDetails'
import { ProjectCardSeparator } from 'root/src/client/web/list/listItemComponents/project/ProjectCardSeparator'

const ProjectCard = memo(() => {
	const { project } = useProjectCard()

	const listRouteId = useListRouteId()

	return (
		<Link
			routeId={listRouteId}
			routeParams={{ recordId: project.projectUrl }}
		>
			<BlackCard.Root className="mx-[10px] mb-[20px]">
				<BlackCard.Header>
					<ProjectCardHeader />
				</BlackCard.Header>
				<BlackCard.Separator>
					<ProjectCardSeparator />
				</BlackCard.Separator>
				<BlackCard.Body className="overflow-visible">
					<ProjectCardDetails />
				</BlackCard.Body>
				<BlackCard.Footer>
					<ProjectCardButton />
				</BlackCard.Footer>
			</BlackCard.Root>
		</Link>
	)
})

export default ProjectCard
