import { useProjectCard } from 'root/src/client/web/list/listItemComponents/project/hooks/useProjectCard'
import { projectDeliveredKey, projectDeliveryPendingKey } from 'root/src/shared/constants/keys'
import ShareMenu from 'root/src/client/web/base/ShareMenu'
import clipHandler from 'root/src/client/logic/project/handlers/clipHandler'
import unescapeHtml from 'root/src/shared/util/unescapeHtml'
import format2Decimals from 'root/src/shared/util/format2Decimals'
import Pin from 'root/src/client/web/base/Pin'
import classNames from 'classnames'
import * as BlackCard from 'root/src/client/v2/common/components/BlackCard/BlackCard'
import { withProjectCardStyle } from 'root/src/client/web/list/listItemComponents/style'
import { rewardForLabels } from 'root/src/client/web/list/listItemComponents/project/ProjectCardButton'
import { ProjectCardRewardRemaining } from 'root/src/client/web/list/listItemComponents/project/ProjectCardRewardRemaining'

const ProjectPin = () => {
	const {
		project: { isPinnedDare },
		actions: { onPinProject },
		extraData: { displayPin },
	} = useProjectCard()

	if (!displayPin) {
		return null
	}

	return <Pin active={isPinnedDare} onClick={onPinProject} />
}

const ProjectCardTitle = withProjectCardStyle(({
	classes, title,
}) => <p className={classNames(classes.projectTitle, classes.textTruncate)}>{title}</p>)

const ProjectCardDescription = withProjectCardStyle(({ classes, description }) => (
	<div className={classNames(
		classes.descriptionContainer,
		classes.descriptionContainerClamp2,
	)}
	>
		<p className={classes.descriptionText}>
			{clipHandler(unescapeHtml(description), 80)}
		</p>
	</div>
))

export const ProjectCardDetails = withProjectCardStyle(({ classes }) => {
	const {
		project: {
			brandProduct,
			title,
			description,
			games, creatorData,
			status,
			pledgeAmount,
			shareUrl,
			capacity,
			rewardFor,
		},
		extraData: { goalAmount },
	} = useProjectCard()

	const completed = status === projectDeliveredKey

	return (
		<div className={classes.footerBody}>
			<div>
				<div className={classes.footerHeaderContainer}>
					<BlackCard.Caption
						title={games.map(game => game.name).join(', ')}
						className={classes.gameTitle}
					/>
					<div style={{
						display: 'flex',
						justifyContent: 'center',
						alignItems: 'center',
					}}
					>
						<ProjectPin />
						<ShareMenu url={shareUrl} />
					</div>
				</div>
				<div style={{ margin: '0px 0px' }}>
					<ProjectCardTitle title={clipHandler(unescapeHtml(title))} />
					<ProjectCardDescription classes={classes} description={description} />
				</div>
			</div>
			{!completed && (
				<div style={{
					display: 'flex',
					justifyContent: 'space-between',
					margin: '5px 0px',
				}}
				>
					<ProjectCardReward
						status={status}
						displayName={creatorData?.displayName}
						capacity={capacity}
						rewardFor={rewardFor}
						brandProduct={brandProduct}
					/>
					{(!brandProduct && !brandProduct?.amount) && (
						<BlackCard.HighlightedSection
							title="TOTAL PLEDGED"
							text={`$${format2Decimals(pledgeAmount)}${goalAmount ? ` of $${format2Decimals(goalAmount)}` : ''}`}
						/>
					)}
				</div>
			)}
		</div>
	)
})

function ProjectCardReward({ status, displayName, capacity, rewardFor, brandProduct }) {
	if ((displayName && status === projectDeliveryPendingKey)
		|| !capacity
		|| !brandProduct?.amount
	) {
		return (
			<BlackCard.HighlightedSection
				title="CREATED BY"
				text={displayName}
			/>
		)
	}

	return (
		<div className="w-[70%]">
			<BlackCard.HighlightedSection
				title={rewardForLabels[rewardFor]}
				text={(brandProduct) ? `(${brandProduct.amount}) ${brandProduct.name}` : undefined}
			/>
			<ProjectCardRewardRemaining />
		</div>
	)
}
